import React from 'react';

interface CourseContext {
  contextData: Record<string, unknown>;
  contextFunctions: Record<string, (...args: unknown[]) => any>;
  updateContextData: (newData: Record<string, unknown>) => void;
  updateContextFunctions: (
    newFunction: Record<string, (...args: unknown[]) => any>
  ) => void;
}

const initialContextValue: CourseContext = {
  contextData: {},
  contextFunctions: {},
  updateContextData: () => {},
  updateContextFunctions: () => {},
};

const Context = React.createContext(initialContextValue);

class ContextProviderComponent extends React.Component<any, CourseContext> {
  /**
   *
   * @param props - Props passed into the React component
   */
  constructor(props) {
    super(props);

    if (props.initialContext) {
      initialContextValue.contextData = {
        ...initialContextValue.contextData,
        ...props.initialContext,
      };
    }

    this.state = {
      ...initialContextValue,
      updateContextData: this.updateData,
      updateContextFunctions: this.updateFunctions,
    };
  }

  /**
   * Adds the object passed as `newData` to global context.
   * If the key of the object passed already exists, it will be replaced with the new value.
   *
   * @param newData - An object containing data to add to context where the key is the name of the data and the value is the data
   */
  updateData = (newData: Record<string, unknown>) => {
    this.setState((state: CourseContext) => ({
      contextData: {
        ...state.contextData,
        ...newData,
      },
    }));
  };

  /**
   * Adds the object passed as `newFunction` to global context.
   * If the key of the object passed already exists, it will be replaced with the new value.
   *
   * @param newFunction - An object containing a function to add to context where the key is the function's name and the value is the function
   */
  updateFunctions = (
    newFunction: Record<string, (...args: unknown[]) => unknown>
  ) => {
    this.setState((state: CourseContext) => ({
      contextFunctions: {
        ...state.contextFunctions,
        ...newFunction,
      },
    }));
  };

  /**
   *
   * @returns A context provider component
   */
  render() {
    return (
      <Context.Provider value={this.state}>
        {this.props.children}
      </Context.Provider>
    );
  }
}

export {Context as default, ContextProviderComponent};
